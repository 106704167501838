import React from "react";

export default function Corallo(){
    return (
        <article>
            <div className="component-title-wrapper">
                <h3>titulo</h3>
                <p>descriptivo</p>
            </div>
            <div className="component-content-wrapper">
                <p>texto
                </p>
                {/* aca abajo copias el codigo iframe de youtube */}
                <iframe width="560" height="315" src="https://www.youtube.com/embed/ROafzxS1QxY?si=Qr6G8333exBHaG_i" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
               
            </div>
        </article>
    )
}