import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import App from './App';
import Home from './components/Home';
import Solo from './components/solo';
import Blast from "./components/Blast";
import Grotte from "./components/LaGrotte";
import Mimosa from './components/Mimosa';
import Corallo from './components/Corallo';
import Bio from './components/Bio';
import Events from './components/Events';
import reportWebVitals from './reportWebVitals';
// importart el componente nuevo
import Joaquin from './components/nuevocomponente';

const router = createBrowserRouter([
  {
       path: '/',
       element: <App />,
       children: [
            {
                 path: '/',
                 element: <Home />,
            },
            {
                path: '/solo',
                element: <Solo />
            },
            { 
                path: '/blast',
                element: <Blast />
            },
            { 
              path: '/laGrotte',
              element: <Grotte />
            },
            {
              path: '/latere',
              element: <Mimosa />
            },
            {
              path: '/collectiveISRAW',
              element: <Corallo />
            },
            {
              path: '/Bio',
              element: <Bio />
            },
            // agregar el componente y el path
            {
              path: '/nuevocomponente',
              element: <Joaquin />
            },
            {
              path: '/events',
              element: <Events />
            }
       ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
