import React from "react";

export default function Events(){
    const dates2025 = [

        
        
        // para agregar una fecha tenes que copiar este bloque
        //para transformar en comentario una linea de codigo el cmd es cmd+shift+/
        // {date:'07.09', proyect: 'en grupo', place:'fulanito'},
        
        // fin del bloque
        
    ]
// si queres agregar fechas es aca.
    const dates2024 = [
        // {date:'07.09', proyect: 'en grupo', place:'fulanito'},
        {date:'12.07', proyect: 'Chen /Eden /Ortega', place:'Jazz à Luz Festival, Luz (FR)'},
        {date:'02.06', proyect: 'dj set', place:'Donau, Porto (PT)'},
        {date:'01.06', proyect: 'dj set', place:'Desterro, Lisbon (PT)'},
        {date:'24.05', proyect: 'Unnecessary Athletics', place:'PASE, Venice (IT)'},
        {date:'16.05-23.05', proyect: 'UA residency', place:'PASE, Venice Biennale (IT)'},
        {date:'14.05', proyect: 'solo', place:'PROGR, Bern (CH)'},
        {date:'25.04', proyect: 'Installation w/G. Martirosyan', place:'Fondation Fiminco, Paris (FR)'},
        {date:'06.03', proyect: 'Unnecessary Athletics', place:'Schöenegg Varieté, Zurich (CH)'},
        {date:'03.03', proyect: 'solo', place:'Fondation Fiminco, Paris (FR)'},
        {date:'12.01', proyect: 'Unnecessary Athletics', place:'Le Cirque Électrique, Paris (FR)'}
        
        
        
        
        
        
        
        
        
        

        
    ]
    const dates2023 = [
        {date:'23.01-28.01', proyect: 'Residency', place:'La fabrique, Saint-Étienne (FR)'},
        {date:'21.03', proyect: 'solo', place:'Les 26 chaises, Paris (FR)'},
        {date:'29.03', proyect: 'Unnecessary Athletics', place:'Cité internationale des arts, Paris (FR)'},
        {date:'05.04', proyect: 'solo', place:'Stimultania, Strasbourg (FR)'},
        {date:'08.04', proyect: 'duo w/Philipp Eden', place:'Amix Kleinbasel studio, Basel (CH)'},
        {date:'11.04', proyect: 'solo', place:'Instants Chavirés, Paris (FR)'},
        {date:'09.05', proyect: 'Unnecessary Athletics', place:'Instants Chavirés, Paris (FR)'},
        {date:'17.06', proyect: 'Unnecessary Athletics', place:'Sport National, CDP, Paris (FR)'},
        {date:'02.11', proyect: 'Unnecessary Athletics', place:'Les Nautes, Paris (FR)'},
        {date:'15.11', proyect: 'duo w/Philipp Eden', place:'Bruitkasten, Cologne (DE)'},
        {date:'17.11', proyect: 'duo w/Philipp Eden', place:'trXt3rr, Berlin (DE)'},
        {date:'18.11', proyect: 'Chen /Eden /Ortega', place:'WestGermany, Berlin (DE)'},
        {date:'20.11', proyect: 'duo w/Philipp Eden', place:'Au Kalme, Brussels (BE)'},
        {date:'21.11', proyect: 'duo w/Philipp Eden', place:'Les 26 chaises, Paris (FR)'},
        {date:'22.11', proyect: 'duo w/Philipp Eden', place:'Schöenegg Varieté, Zurich (CH)'},
        {date:'23.11', proyect: 'duo w/Philipp Eden', place:'Wabe, Basel (CH)'}
        
    ]

    const dates2022 = [,
    { date: '16.12', proyect: 'solo', place:'L\'Atelier des Canulars, Lyon (FR)'},
    { date:'03.12', proyect:'solo', place:'Le Figuier Pourpre, Avignon (FR)'},
    { date:'01.12', proyect:'duo w/Marion Jo', place:'s.l, Toulouse (FR)'},
    { date:'25.11', proyect:'solo', place:'La Tortuga, Madrid (ES)'},
    { date:'24.11', proyect:'solo', place:'Cruce, Madrid (ES)'}, 
    { date:'20.11', proyect:'Latere dance performance', place:'Migrats Dansa CCCC, Valencia (ES)'}, 
    { date:'19.11', proyect:'Latere, dance performance', place:'Migrats Dansa CCCC, Valencia (ES)'},
    { date:'16.11', proyect:'solo', place:'Le Taquin, Toulouse (FR)'},
    { date:'06.11', proyect:'duo w/Marion Jo', place:'Le Poinçonneur, Toulouse (FR)'},
    { date:'01.11 - 06.11', proyect:'solo recording', place:'studio éole, Toulouse (FR)'},
    { date:'26.10', proyect:'solo', place:'Cité internationale des arts, Paris (FR)'} 
    ]
// no tocar // despues de return es el codigo que visualisas
    return (
        <article>
           
            <div className="component-title-wrapper">
                <h3>2025</h3>
            </div>
            {/* no te olvides de cambiar el año */}
                {dates2025.map((e,i)=>(
                <div className="events-container" key={i}>
                    <p>{e.date}</p> 
                    <p>{e.proyect}</p>
                    <p>{e.place}</p>
                </div>
                ))}

            {/* codigo para cada año copiar este bloque y cambiar el año al actual*/}
            <div className="component-title-wrapper">
                <h3>2024</h3>
            </div>
                {dates2024.map((e,i)=>(
                <div className="events-container" key={i}>
                    <p>{e.date}</p> 
                    <p>{e.proyect}</p>
                    <p>{e.place}</p>
                </div>
                ))}

                {/* aca termina el bloque del año */}
            <div className="component-title-wrapper">
                <h3>2023</h3>
            </div>
                {dates2023.map((e,i)=>(
                <div className="events-container" key={i}>
                    <p>{e.date}</p> 
                    <p>{e.proyect}</p>
                    <p>{e.place}</p>
                </div>
                ))}
            <div className="component-title-wrapper">
                <h3>2022</h3>
            </div>
                {dates2022.map((e,i)=>(
                <div className="events-container" key={i}>
                    <p>{e.date}</p> 
                    <p>{e.proyect}</p>
                    <p>{e.place}</p>
                </div>
                ))}
        </article>

    )
}